import './go.css';

// The time in milliseconds before activating dokbot
const BRAND_DELAY = 750;

const args = { env: process.env.NODE_ENV, params: {}, ...window.DOKBOT_CONFIG };

let search = '',
  segments = [];

if (args.env === 'development') {
  const parts = window.location.hash.substring(1).split('?');

  segments = parts[0].split('/');
  search = parts[1] || '';
} else {
  segments = window.location.pathname.split('/');
  search = (window.location.search || '').substr(1);
}

args.consultationId = args.consultationId || segments[1];

// Namespaced workflow slugs start with an '@' and include a '/' to separate orgname from slug. Ex. @dokbot/developer
// For namespaced workflows, need to include two segments
let namespaced = false;
if (segments[2]?.includes('@')) {
  args.workflowId = segments[3];
  args.orgSlug = segments[2].slice(1);
  namespaced = true;
} else {
  args.workflowId = args.workflowId || segments[2];
}

if (!args.workflowId) {
  throw new Error('No workflow id or slug provided');
}

if (args.consultationId === '+') {
  // If evergreen, there might be an organization specified but never an activation code.
  args.orgId = args.orgId || (namespaced ? segments[4] : segments[3]);
  const useWIPsegment = namespaced ? segments[5] : segments[4];
  const useWIP = useWIPsegment === 'wip';
  args.useWIP = args.useWIP || useWIP;
} else {
  // If a specified consultation, there might be an activiation code specified if re-entering but never an organization.
  args.activationCode = args.activationCode || (namespaced ? segments[4] : segments[3]);
}

if (search) {
  search.split('&').forEach(function (item) {
    const s = item.split('=');
    const k = s[0];
    args.params[k] = args.params[k] || (s[1] && decodeURIComponent(s[1]));
  });
}

const t0 = Date.now();

args.onReady = (consultation, style) => {
  const dt = Date.now() - t0;
  const body = document.querySelector('body');

  const activate = () => {
    Object.keys(style).forEach((k) => {
      body.style.setProperty(k, style[k]);
    });

    body.classList.add('ready');
  };

  if (dt < BRAND_DELAY) {
    setTimeout(activate, BRAND_DELAY - dt);
  } else {
    activate();
  }
};

if (Dokbot && Dokbot.meta.isBrowserSupported) {
  Dokbot.RuntimeComponent(args).render('#dokbot');
}

export { args };
